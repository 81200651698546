import React from 'react';

import Image from '@/components/Image';

import Headlines from '../../common/Headlines';
import Pills from '../../common/Pills';
import ScrollMoreText from '../../common/ScrollMoreText';
import Slots, { HeroSlotType } from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface HomepageHeroWithImageProps extends BaseVariantProps {
  headlines: string[];
  image: Nullable<AssetFragment>;
  pills: Nullable<HomepageHeroCtaPillsFragment[]>;
  overrideBackground?: Nullable<string>;
  variant: 'homepageHeroWithImage';
}

const HomepageHeroWithImage = ({ slots, headlines, image, pills, overrideBackground }: HomepageHeroWithImageProps) => {
  const includeCarButtonOrForm = slots.some((slot: HeroSlotType) =>
    ['slotDynamicButton', 'slotDynamicForm'].includes(slot.type),
  );
  return (
    <div
      className={styles.homepageHeroWithImage}
      style={overrideBackground ? { backgroundColor: overrideBackground } : {}}
    >
      <div className={styles.headlinesContainer}>{headlines.length >= 1 && <Headlines headlines={headlines} />}</div>
      {image && (
        <div className={styles.imageContainer}>
          <Image {...image} className={styles.image} />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.slotsWrapper}>&nbsp;</div>
          <div className={styles.side}>
            {includeCarButtonOrForm && <h2>Find your next car</h2>}
            <div className={styles.formContainer}>
              {slots.map((slot, index) => (
                <React.Fragment key={`${slot.type}-${index}`}>
                  {index === slots.length - 1 && index !== 0 && <div className={styles.or}>or</div>}
                  <Slots slots={[slot]} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      {!!pills && pills.length > 0 && <Pills items={pills} />}
      <ScrollMoreText />
    </div>
  );
};

export default HomepageHeroWithImage;
