import React from 'react';

import ImageOrPlaceholder from '@/components/ContentModule/common/ImageOrPlaceholder';

import FeaturedImages from '../../common/FeaturedImages';
import HeroFormBlock, { HeroFormBlockProps } from '../../common/HeroFormBlock';
import Slots from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface WithFormProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  form: HeroFormBlockProps;
  variant: 'withForm';
}

export default function HeroWithForm({ slots, form, image, imageSlots }: WithFormProps) {
  return (
    <React.Fragment>
      <div className={styles.heroWithForm}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.slotsWrapper}>
              <Slots slots={slots} />
            </div>
            <div className={styles.side}>
              <div className={styles.formWrapper}>
                <HeroFormBlock {...form} />
              </div>
            </div>
            {image && (
              <div className={styles.imageWrapper}>
                <ImageOrPlaceholder image={image} className={styles.image} />
              </div>
            )}
          </div>
        </div>
      </div>
      {imageSlots.length > 0 && (
        <FeaturedImages>
          <Slots slots={imageSlots} />
        </FeaturedImages>
      )}
    </React.Fragment>
  );
}
