import { HeroSlots } from '@/components/HeroModule';
import { HeroSlotType } from '@/components/HeroModule/slots';
import { extractItems } from '@/lib/GraphQLHelper';

type ChildNode =
  | VizyHeadingFragment
  | VizyParagraphFragment
  | VizyBulletListFragment
  | VizyOrderedListFragment
  | SubHeroContentButtonFragment
  | SubHeroContentDualButtonFragment
  | SubHeroContentChecklistFragment;

export function convertToSlot(node: ChildNode): Nullable<HeroSlotType> {
  switch (node.__typename) {
    case 'VizyNode_Heading':
      if (!node.html) return null;
      return HeroSlots.Heading(node.html);
    case 'VizyNode_Paragraph':
      if (!node.html) return null;
      return HeroSlots.Paragraph(node.html);
    case 'VizyNode_BulletList':
    case 'VizyNode_OrderedList':
      if (!node.html) return null;
      return HeroSlots.List(node.html);
    case 'subHeroContent_button_BlockType':
      if (!node.customLinkField) return null;
      return HeroSlots.Buttons(node.customLinkField, undefined, node.heroModuleDynamicButtonStyle!);
    case 'subHeroContent_dualButtons_BlockType':
      if (!node.customLinkField || !node.customLinkField2) return null;
      return HeroSlots.Buttons(node.customLinkField, node.customLinkField2, node.heroModuleDynamicButtonStyle!);
    case 'subHeroContent_checklist_BlockType':
      if (!node.checklist) return null;
      return HeroSlots.Checklist(node.checklist);
    default:
      return null;
  }
}

export default function convertToSlots(node?: Nullable<SubHeroContentFragment>) {
  const nodes =
    extractItems<ChildNode>(node?.nodes)
      ?.map(convertToSlot)
      .filter((s): s is HeroSlotType => !!s) || [];

  return nodes;
}
