import React from 'react';

import Button from '@/components/Button';

import styles from './styles.module.scss';

export interface SlotButton {
  text: string;
  url: string;
  target?: string;
}

export interface SlotButtonsProps {
  button1: CustomLinkFieldFragment;
  button2?: CustomLinkFieldFragment;
  buttonType?: string;
  type: 'slotButtons';
}

interface ButtonStyleMap {
  button1: ButtonType;
  button2: ButtonType;
}

const themeButtonStyleMap: Record<string, ButtonStyleMap> = {
  brand: {
    button1: 'brand',
    button2: 'brand-transparent',
  },
  action: {
    button1: 'action',
    button2: 'action-transparent',
  },
  foreground: {
    button1: 'foreground',
    button2: 'foreground-transparent',
  },
  'action-light': {
    button1: 'action-light',
    button2: 'action-light-transparent',
  },
};

const defaultStyleMap: ButtonStyleMap = {
  button1: 'button',
  button2: 'blue-transparent',
};

export default function SlotButtons({ button1, button2, buttonType }: SlotButtonsProps) {
  const styleMap = buttonType && themeButtonStyleMap[buttonType] ? themeButtonStyleMap[buttonType] : defaultStyleMap;
  return (
    <div className={styles.slotButtons}>
      <Button href={button1.url!} target={button1.target || undefined} type={styleMap.button1}>
        {button1.text}
      </Button>
      {button2 && (
        <Button href={button2.url!} target={button1.target || undefined} type={styleMap.button2}>
          {button2.text}
        </Button>
      )}
    </div>
  );
}
