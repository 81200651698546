import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { uniq } from 'lodash';
import slugify from 'slugify';
import * as Yup from 'yup';

import Button from '@/components/Button';
import Select, { SelectOption } from '@/components/fields/Select';
import TextField from '@/components/fields/TextField';
import useStore from '@/hooks/useStore';
import { stateOptions, states } from '@/lib/AddressFormat';
import { getStatePlate } from '@/lib/getStatePlate';
import { extractErrors } from '@/lib/WarlockUtils';

import styles from './styles.module.scss';

interface SellMyCarInit {
  rego: string;
  state: string;
}

interface Props {
  formType: string;
  buttonStyle?: string;
  mainDealershipSlug?: string;
}

const RegoLookupBlock = ({ formType, buttonStyle, mainDealershipSlug }: Props) => {
  const {
    customization: { defaultStateOption },
  } = useStore('globals');
  const defaultState = defaultStateOption && stateOptions.includes(defaultStateOption) ? defaultStateOption : 'WA';

  const initialValues: SellMyCarInit = {
    rego: '',
    state: defaultState,
  };

  const findMakeModelLink = useMemo(() => {
    let link = '';
    switch (formType) {
      case 'consignment':
        link = '/consignment/book?makeModelLookup=true';
        break;
      case 'servicing':
        if (mainDealershipSlug) {
          link = `/car-servicing/book/${slugify(mainDealershipSlug, { lower: true })}?makeModelLookup=true`;
        } else {
          link = '/car-servicing/book?makeModelLookup=true';
        }
        break;
      case 'sellMyCar':
      default:
        link = '/sell-my-car/book?makeModelLookup=true';
        break;
    }
    return link;
  }, [formType, mainDealershipSlug]);

  const submitButtonStyle: ButtonType = useMemo(
    () => (buttonStyle && RegoLookupBlock.buttonStyles.includes(buttonStyle) ? (buttonStyle as ButtonType) : 'action'),
    [buttonStyle],
  );

  const handleSubmit = async (values: SellMyCarInit) => {
    const params = `?rego=${values.rego}&regoState=${values.state}`;
    switch (formType) {
      case 'consignment':
        window.location.href = `/consignment/book${params}`;
        break;
      case 'servicing':
        if (mainDealershipSlug) {
          window.location.href = `/car-servicing/book/${slugify(mainDealershipSlug, { lower: true })}${params}`;
        } else {
          window.location.href = `/car-servicing/book${params}`;
        }
        break;
      case 'sellMyCar':
      default:
        window.location.href = `/sell-my-car/book${params}`;
        break;
    }
    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={RegoLookupBlock.validationSchema}
      validateOnMount={false}
      validateOnChange
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleSubmit, values, setFieldValue, handleBlur }) => {
        const errorMessage = extractErrors('rego', touched, errors, 'Rego');
        return (
          <form data-testid="contact-form" onSubmit={handleSubmit}>
            <div className={styles.findRego}>
              <div className={styles.regoWrapper}>
                <div className={styles.regoFieldWrapped}>
                  <div className={styles.regoField}>
                    <TextField
                      name="rego"
                      severity={!!errorMessage ? 'error' : undefined}
                      validationText={errorMessage}
                      label="Vehicle registration"
                      placeholder="Enter rego plate"
                      onChange={(e) => setFieldValue('rego', e.target.value)}
                      onBlur={handleBlur('rego')}
                    />
                    <Select
                      name="rego"
                      label="State"
                      value={values.state}
                      onChange={(value) => setFieldValue('state', value)}
                    >
                      {uniq(Object.values(states))
                        .sort((state: string) => (state.toLowerCase() === 'wa' ? 1 : 0))
                        .map((state: string) => (
                          <SelectOption key={state} value={state} label={state} />
                        ))}
                    </Select>
                  </div>
                </div>
                <div className={styles.regoDesc}>
                  {getStatePlate(values.state)}
                  <p>Enter your registration number to quickly identify your car</p>
                </div>
              </div>
              <Button type={submitButtonStyle} data-testid="find-my-car-button" styleOverride={styles.button}>
                Find my car
              </Button>
              <a data-testid="stepswap-btn" className={styles.stepSwap} type="button" href={findMakeModelLink}>
                Find car by make, model &amp; year
              </a>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

RegoLookupBlock.validationSchema = Yup.object().shape({
  rego: Yup.string().required(),
  state: Yup.string().required(),
});
RegoLookupBlock.buttonStyles = ['brand', 'action', 'foreground', 'action-light'];

export default RegoLookupBlock;
