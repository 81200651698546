import React from 'react';

import { getIcon } from '@/lib/IconHelper';

import styles from './styles.module.scss';

interface SlotHeadingWithIconProps {
  heading: string;
  icon?: Maybe<IconFragment>;
  type: 'slotHeadingWithIcon';
}

export default function SlotHeadingWithIcon({ heading, icon }: SlotHeadingWithIconProps) {
  return (
    <h1 className={styles.slotHeadingWithIcon}>
      {icon && getIcon(icon)}
      <div dangerouslySetInnerHTML={{ __html: heading }} />
    </h1>
  );
}

export type { SlotHeadingWithIconProps };
