import React from 'react';

import styles from './styles.module.scss';

interface SlotParagraphProps {
  paragraph: string;
  type: 'slotParagraph';
}

export default function SlotParagraph({ paragraph }: SlotParagraphProps) {
  return (
    <div className={styles.slotParagraph}>
      <div dangerouslySetInnerHTML={{ __html: paragraph }} />
    </div>
  );
}

export type { SlotParagraphProps };
