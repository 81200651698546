import 'keen-slider/keen-slider.min.css';

import React, { useCallback, useEffect, useState } from 'react';
import cc from 'classcat';
import { useKeenSlider } from 'keen-slider/react';

import Image from '@/components/Image';

import { SlotImageProps } from '../../slots/Image';

import styles from './styles.module.scss';

interface Props {
  images: SlotImageProps[];
}

const HeroLandingCarousel = ({ images }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [opacities, setOpacities] = React.useState<number[]>([]);

  const [counter, setCounter] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel);
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion);
      setOpacities(newOpacities);
    },
    slides: images.length,
    loop: true,
    initial: 0,
    drag: false,
    defaultAnimation: {
      duration: 300,
      easing: (t) => t,
    },
  });

  useEffect(() => {
    const timerId = setInterval(() => {
      setCounter((c) => (c + 1) % (HeroLandingCarousel.timeChange + 1));
      if (counter === HeroLandingCarousel.timeChange) {
        instanceRef.current?.next();
      }
    }, 500);

    return () => {
      clearInterval(timerId);
    };
  }, [counter, instanceRef]);

  const changeSlide = useCallback(
    (index: number) => {
      if (currentSlide !== index) {
        instanceRef.current?.moveToIdx(index);
        setCounter(0);
      }
    },
    [instanceRef, currentSlide],
  );

  return (
    <div className={styles.carouselWrapper}>
      <div ref={sliderRef} className={`keen-slider keen-slider-landing-carousel fader ${styles.carouselContainer}`}>
        {images.map(
          (image, idx) =>
            image.images[0] && (
              <div key={idx} className="fader__slide" style={{ opacity: opacities[idx] }}>
                <Image {...image.images[0]} alt={image.label} className={styles.image} />
              </div>
            ),
        )}
      </div>

      <div className={styles.buttons}>
        <div className={styles.buttonsContainer}>
          {images.map(
            (image, idx) =>
              image.images[0]?.jpg && (
                <button
                  className={cc({ [styles.slideButton]: true, [styles.slideButtonActive]: currentSlide === idx })}
                  key={idx}
                  onClick={() => changeSlide(idx)}
                />
              ),
          )}
        </div>
      </div>
    </div>
  );
};

HeroLandingCarousel.timeChange = 10;

export default HeroLandingCarousel;
