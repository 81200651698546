import React from 'react';

import HeroVideo from '../../common/HeroVideo';
import Slots from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface WithVideoProps extends BaseVariantProps {
  videos: VideoAssetFragment[];
  image?: Nullable<AssetFragment>;
  variant: 'withVideo';
}

export default function HeroWithVideo({ slots, videos, image }: WithVideoProps) {
  return (
    <React.Fragment>
      <div className={styles.heroWithVideo}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.slotsWrapper}>
              <Slots slots={slots} />
            </div>
            <div className={styles.videoWrapper}>
              <HeroVideo videos={videos} poster={image!} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
