import React from 'react';

import Headlines from '../../common/Headlines';
import HeroCarousel from '../../common/HeroCarousel';
import ScrollMoreText from '../../common/ScrollMoreText';
import Slots, { HeroSlotType } from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface HomepageHeroWithCarouselProps extends BaseVariantProps {
  headlines: string[];
  variant: 'homepageHeroWithCarousel';
}

const HomepageHeroWithCarousel = ({ slots, headlines, imageSlots }: HomepageHeroWithCarouselProps) => {
  const includeCarButtonOrForm = slots.some((slot: HeroSlotType) =>
    ['slotDynamicButton', 'slotDynamicForm'].includes(slot.type),
  );
  return (
    <div className={styles.homepageHeroWithCarousel}>
      <HeroCarousel images={imageSlots} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.slotsWrapper}>
            {headlines.length >= 1 && <Headlines headlines={headlines} size="small" />}
          </div>
          <div className={styles.side}>
            {includeCarButtonOrForm && <h2>Find your next car</h2>}
            <div className={styles.formContainer}>
              {slots.map((slot, index) => (
                <React.Fragment key={`${slot.type}-${index}`}>
                  {index === slots.length - 1 && index !== 0 && <div className={styles.or}>or</div>}
                  <Slots slots={[slot]} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ScrollMoreText />
    </div>
  );
};

export default HomepageHeroWithCarousel;
