import React from 'react';

import HeaderLogo from '@/components/layout/HeaderLogo';
import useStore from '@/hooks/useStore';

import HeroLandingCarousel from '../../common/HeroLandingCarousel';
import Slots from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface HeroLandingProps extends BaseVariantProps {
  overrideBackground?: Nullable<string>;
  variant: 'heroLanding';
}

const HeroLanding = ({ slots, imageSlots, overrideBackground }: HeroLandingProps) => {
  const { siteConfig } = useStore('globals');
  const copyText = `© ${HeroLanding.year} ${siteConfig?.siteGroup === 'servicing' ? 'IDOM PTY LTD.' : 'Autoleague'}`;

  return (
    <div className={styles.heroLanding} style={overrideBackground ? { backgroundColor: overrideBackground } : {}}>
      <HeroLandingCarousel images={imageSlots} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.side}>
            <div className={styles.logo}>
              <HeaderLogo />
            </div>
            <div className={styles.formContainer}>
              {slots.map((slot, index) => (
                <React.Fragment key={`${slot.type}-${index}`}>
                  <Slots slots={[slot]} />
                </React.Fragment>
              ))}
            </div>
            <p className={styles.copyRight}>
              {copyText}
              &nbsp;
              <a href="/privacy">Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroLanding.year = new Date().getFullYear();

export default HeroLanding;
