import 'keen-slider/keen-slider.min.css';

import React, { useEffect, useState } from 'react';
import cc from 'classcat';
import { useKeenSlider } from 'keen-slider/react';

interface Props {
  headlines: string[];
  size?: 'default' | 'small';
}

const Headlines = ({ headlines, size = 'default' }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel);
    },
    loop: true,
    initial: 0,
    vertical: true,
    drag: true,
    defaultAnimation: {
      duration: 300,
      easing: (t) => t,
    },
  });

  useEffect(() => {
    const slideInterval = setInterval(() => {
      instanceRef.current?.next();
    }, 5000);

    return () => {
      clearInterval(slideInterval);
    };
  }, [currentSlide, instanceRef]);

  return (
    <div
      ref={sliderRef}
      className={cc({
        'keen-slider': true,
        'keen-slider-headline': true,
        'keen-slider-headline-small': size === 'small',
      })}
    >
      {headlines.map((headline, idx) => (
        <div key={idx} className="keen-slider__slide">
          {headline}
        </div>
      ))}
    </div>
  );
};

export default Headlines;
