import React from 'react';
import { useQuery } from 'urql';

import Button from '@/components/Button';
import useStore from '@/hooks/useStore';
import { getDealerFilterString } from '@/lib/getServerSideProps';
import stockQuery from '@/lib/Stock/stock.graphql';
import StockViewCount from '@/lib/StockViewCount';
import { SSRContext } from '@/typings/SSRContext';

import styles from './styles.module.scss';

export interface SlotDynamicButtonProps {
  parentComponent?: string;
  button: SubHeroHomepageCtaDynamicButtonFragment;
  type: 'slotDynamicButton';
}

export type slotDynamicButtonVariants = 'shopTotalCars';

export default function SlotDynamicButton({ button }: SlotDynamicButtonProps) {
  // Switch case render variants
  const { dealershipsArray } = useStore('dealershipsMap');
  const { isUsedCarPlatform } = useStore('globals');

  switch (button.heroModuleDynamicButtonVariants as slotDynamicButtonVariants) {
    case 'shopTotalCars':
      const dealerFilterString = isUsedCarPlatform ? getDealerFilterString(dealershipsArray) : '';

      return <VariantShopTotalCars type={button.heroModuleDynamicButtonStyle} filters={dealerFilterString} />;
    default:
      return null;
  }
}

SlotDynamicButton.prefetch = async (
  { urqlClient, nextCtx: { locale }, stores: { globals } }: SSRContext,
  dynamicButtonVariant: slotDynamicButtonVariants,
) => {
  switch (dynamicButtonVariant) {
    case 'shopTotalCars':
      const filterString = globals.isUsedCarPlatform
        ? (await StockViewCount.getDealerIdFilters(urqlClient, locale!)) ?? ''
        : '';
      await urqlClient
        ?.query<StockQuery>(stockQuery, {
          filters: filterString,
          limit: 1,
        })
        .toPromise();
    default:
  }
};
//Variants
interface Props {
  type?: Maybe<string>;
  filters?: string;
}

const VariantShopTotalCars = ({ type, filters = '' }: Props) => {
  const [{ data: stockQueryResult }] = useQuery<StockQuery>({
    query: stockQuery,
    variables: {
      filters,
      limit: 1,
    },
  });
  const total = stockQueryResult?.stockList?.total ?? VariantShopTotalCars.defaultTotal;

  if (type && VariantShopTotalCars.buttonStyles.includes(type)) {
    return (
      <Button
        href={VariantShopTotalCars.url}
        type={type as ButtonType}
        size="medium"
        data-testid="shop-total-cars-button"
      >
        Shop {total.toLocaleString()} cars
      </Button>
    );
  }

  return (
    <Button href={VariantShopTotalCars.url} styleOverride={styles.shopTotalCars} data-testid="shop-total-cars-button">
      Shop {total.toLocaleString()} cars
    </Button>
  );
};

VariantShopTotalCars.buttonStyles = ['brand', 'action', 'foreground', 'action-light'];
VariantShopTotalCars.defaultTotal = '3,000+';
VariantShopTotalCars.url = '/cars';
