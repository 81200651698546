import React from 'react';

import styles from './styles.module.scss';

interface SlotHeadingProps {
  heading: string;
  type: 'slotHeading';
}

export default function SlotHeading({ heading }: SlotHeadingProps) {
  return (
    <div className={styles.slotHeading}>
      <div dangerouslySetInnerHTML={{ __html: heading }} />
    </div>
  );
}

export type { SlotHeadingProps };
