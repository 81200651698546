import React from 'react';

import HeroFormBlock, { HeroFormBlockProps } from '../../common/HeroFormBlock';
import Slots from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface HomepageHeroWithFormProps extends BaseVariantProps {
  form: Nullable<HeroFormBlockProps>;
  variant: 'homepageHeroWithForm';
}

const HomepageHeroWithForm = ({ slots, form, imageSlots }: HomepageHeroWithFormProps) => {
  return (
    <div className={styles.homepageHeroWithForm}>
      <div className={styles.backgroundTile} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.slotsWrapper}>
            <Slots slots={slots} />
          </div>
          <div className={styles.formWrapper}>{form && <HeroFormBlock {...form} />}</div>
        </div>
      </div>
      {imageSlots.length > 0 && (
        <div className={styles.carImages}>
          <Slots slots={imageSlots} />
        </div>
      )}
    </div>
  );
};

export default HomepageHeroWithForm;
