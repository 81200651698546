import React from 'react';

import styles from './styles.module.scss';

export interface SlotListProps {
  list: string;
  type: 'slotList';
}

export default function SlotList({ list }: SlotListProps) {
  return (
    <div className={styles.slotList}>
      <div dangerouslySetInnerHTML={{ __html: list }} />
    </div>
  );
}
