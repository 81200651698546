import React from 'react';

import Button from '@/components/Button';
import { getIcon } from '@/lib/IconHelper';

interface Props {
  button: { link: CustomLinkFieldFragment; icon: Maybe<IconFragment>; buttonStyle: Maybe<string> };
  transparent: boolean;
}

interface ButtonStyleMap {
  button1: ButtonType;
  button2: ButtonType;
}

const themeButtonStyleMap: Record<string, ButtonStyleMap> = {
  brand: {
    button1: 'brand',
    button2: 'brand-transparent',
  },
  action: {
    button1: 'action',
    button2: 'action-transparent',
  },
  foreground: {
    button1: 'foreground',
    button2: 'foreground-transparent',
  },
  'action-light': {
    button1: 'action-light',
    button2: 'action-light-transparent',
  },
};

const defaultStyleMap: ButtonStyleMap = {
  button1: 'button',
  button2: 'blue-transparent',
};

export default function HeroFormButton({ button, transparent }: Props) {
  const styleMap =
    button.buttonStyle && themeButtonStyleMap[button.buttonStyle]
      ? themeButtonStyleMap[button.buttonStyle]
      : defaultStyleMap;

  return (
    <Button
      key={button.link.text}
      href={button.link.url!}
      target={button.link.target || undefined}
      type={transparent ? styleMap.button2 : styleMap.button1}
      data-testid="hero-form-button"
    >
      {button.link.text}
      {button.icon?.inline && getIcon(button.icon)}
    </Button>
  );
}
