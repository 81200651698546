import React from 'react';
import cc from 'classcat';

import SlotButtons, { SlotButtonsProps } from './Buttons';
import SlotChecklist, { SlotChecklistProps } from './Checklist';
import SlotDynamicButton, { SlotDynamicButtonProps } from './DynamicButton';
import SlotDynamicForm, { SlotDynamicFormProps } from './DynamicForm';
import SlotHeading, { SlotHeadingProps } from './Heading';
import SlotHeadingWithIcon, { SlotHeadingWithIconProps } from './HeadingWithIcon';
import SlotImage, { SlotImageProps } from './Image';
import SlotList, { SlotListProps } from './List';
import SlotParagraph, { SlotParagraphProps } from './Paragraph';
import SlotStaticButton, { SlotStaticButtonProps } from './StaticButton';

import styles from './styles.module.scss';

export type HeroSlotType =
  | SlotHeadingProps
  | SlotHeadingWithIconProps
  | SlotButtonsProps
  | SlotParagraphProps
  | SlotListProps
  | SlotChecklistProps
  | SlotImageProps
  | SlotDynamicButtonProps
  | SlotDynamicFormProps
  | SlotStaticButtonProps;

interface HeroSlotsProps {
  slots: Array<HeroSlotType>;
  alignment?: 'left' | 'center' | 'right';
}

export default function Slots({ slots, alignment = 'left' }: HeroSlotsProps) {
  return (
    <div className={cc({ [styles.slots]: true, [styles[alignment]]: true })}>
      {slots.map((slot, index) => (
        <Slot slot={slot} key={`${slot.type}-${index}`} />
      ))}
    </div>
  );
}

interface HeroSlotProps {
  slot: HeroSlotType;
}

export function Slot({ slot }: HeroSlotProps) {
  switch (slot.type) {
    case 'slotButtons':
      return <SlotButtons {...slot} />;
    case 'slotChecklist':
      return <SlotChecklist {...slot} />;
    case 'slotHeading':
      return <SlotHeading {...slot} />;
    case 'slotHeadingWithIcon':
      return <SlotHeadingWithIcon {...slot} />;
    case 'slotParagraph':
      return <SlotParagraph {...slot} />;
    case 'slotList':
      return <SlotList {...slot} />;
    case 'slotImage':
      return <SlotImage {...slot} />;
    case 'slotDynamicButton':
      return <SlotDynamicButton {...slot} />;
    case 'slotDynamicForm':
      return <SlotDynamicForm {...slot} />;
    case 'slotStaticButton':
      return <SlotStaticButton {...slot} />;
    /* istanbul ignore next */
    default:
      return null;
  }
}
