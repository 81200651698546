import React from 'react';
import cc from 'classcat';

import Button from '@/components/Button';

import styles from './styles.module.scss';

export interface SlotStaticButton {
  text: string;
  url: string;
  target?: string;
}

export interface SlotStaticButtonProps {
  button: SubHeroHomepageStaticButtonFragment | SubHeroLandingContentStaticButtonFragment;
  type: 'slotStaticButton';
}

export type slotStaticButtonVariants =
  | 'action'
  | 'action-transparent'
  | 'brand'
  | 'brand-transparent'
  | 'foreground'
  | 'foreground-transparent'
  | 'action-light'
  | 'disabled';

export default function SlotStaticButton({ button }: SlotStaticButtonProps) {
  const customLinkField = button.customLinkField;
  const buttonVariant = (button.heroModuleStaticButtonStyle || 'action') as ButtonType;
  const buttonSize = (button.heroModuleStaticButtonSize || 'medium') as ButtonSize;

  if (!customLinkField) return null;

  return (
    <div className={cc([{ [styles.slotStaticButton]: true, [styles.slotStaticLargeButton]: buttonSize === 'large' }])}>
      <Button
        href={customLinkField.url!}
        target={customLinkField.target || undefined}
        type={buttonVariant}
        size={buttonSize}
        styleOverride={styles.slotStaticButtonStyle}
      >
        {customLinkField.text}
      </Button>
    </div>
  );
}
