import React from 'react';
import cc from 'classcat';

import Image from '@/components/Image';
import { IconQuestionMark } from '@/images/icons/tabler-icons';
import { getIcon } from '@/lib/IconHelper';
import { urlFor } from '@/lib/Link';

import Slots from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface HomepageHeroProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  pills?: Nullable<HomepageHeroCtaPillsFragment[]>;
  linkField?: Nullable<LinkEntryFragment | LinkUrlFragment>;
  pillsLayout?: PillsLayoutType;
  variant: 'homepageHero';
}

export type PillsLayoutType = 'default' | 'alignRight';

const HomepageHero = ({ slots, image, pills, linkField, pillsLayout }: HomepageHeroProps) => {
  const pillLayoutStyle = pillsLayout || 'default';

  return (
    <div className={styles.homepageHero}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.leftContent}>
            <Slots slots={slots} />
          </div>
        </div>
        <div className={styles.right}>
          {!!pills && (
            <div
              className={cc({
                [styles.pills]: true,
                [styles[pillLayoutStyle]]: true,
                [styles.withLinkField]: !!linkField,
              })}
            >
              {pills.map((p, i) => (
                <span key={i} className={p.reverseColours ? styles.reverse : undefined}>
                  {getIcon(p.icon)} {p.text}
                </span>
              ))}
            </div>
          )}
          {image && (
            <div className={styles.tile}>
              <div className={styles.imageWrapper}>
                <Image data-testid="contentModuleImage" preload {...image} loading="eager" className={styles.image} />
              </div>
              {!!linkField && (
                <a className={styles.link} href={urlFor(linkField)}>
                  {linkField.text}
                  <IconQuestionMark width={24} height={24} />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomepageHero;
