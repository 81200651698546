import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { capitalize } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import SearchBase from '@/components/SearchBase';
import { allowSearch } from '@/lib/SearchWhitelist';
import SearchWordHighlight from '@/lib/SearchWordHighlight';
import { stockSlugify } from '@/lib/slugify';
import StockService from '@/lib/StockService';
import { makeMap, toTitleCase } from '@/lib/utils';

import styles from './styles.module.scss';

type Suggestion = {
  searchValue: string;
  result: AutoCompleteItem;
};

const HighlightMatch = (match: string, searchValue: string) => {
  const { chains, startingIndex } = SearchWordHighlight(match, searchValue.toLowerCase());
  return chains.map((chain, i) => (
    <span key={i}>{i % 2 === startingIndex ? <strong>{chain}</strong> : <>{chain}</>}</span>
  ));
};

// Must be a pure function, displays suggestion in dropdown
const RenderSuggestion = (suggestion: Suggestion) => {
  const { result, searchValue } = suggestion;
  let suffix: JSX.Element | JSX.Element[] | string = '';

  switch (result.type) {
    case 'Make':
      suffix = HighlightMatch(toTitleCase(makeMap(result.match)) ?? result.match, searchValue);
      break;

    case 'MakeModel':
      if (Array.isArray(result.extras) && result.extras.length >= 2) {
        suffix = toTitleCase(makeMap(result.extras[0])) + ' ' + toTitleCase(result.extras[1]);
      } else {
        suffix = toTitleCase(result.match) ?? result.match;
      }
      suffix = HighlightMatch(suffix, searchValue);
      break;

    case 'Price':
    case 'StockNo':
    case 'GenericColour':
    case 'Feature':
    case 'Keyword':
    default:
      break;
  }

  if (!suffix) {
    return null;
  }

  return <p key={`${result.type}-${result.match}`}>{suffix}</p>;
};

const emptyQuerySuggestion: Suggestion = {
  searchValue: '',
  result: {
    match: '',
    type: 'Keyword',
  },
};

const HeroSearch = () => {
  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);
  const [query, setQuery] = useState<Suggestion>(emptyQuerySuggestion);
  const [searchValue, setSearchValue] = useState('');

  const selectSuggestion = useCallback((suggestion: AutoCompleteItem) => {
    let url = '/cars';
    switch (suggestion.type) {
      case 'Make':
        url = `/cars/${stockSlugify(suggestion.match)}`;
        break;
      case 'MakeModel':
        if (!!suggestion?.extras?.length) {
          url = `/cars/${stockSlugify(suggestion.extras[0])}/${stockSlugify(suggestion.extras[1])}`;
        }
        break;
      case 'StockNo':
      case 'Price':
      case 'GenericColour':
      case 'Feature':
      case 'Keyword':
      default:
        break;
    }
    window.location.assign(url);
  }, []);

  const getSuggestions = useDebouncedCallback(async (value: string) => {
    const data: AutoCompleteItem[] | { error: string } = await StockService.autocomplete(value);
    // StockServer.autocomplete can return an object w/ error, ignore if it does (char lim exceeded)
    if (Array.isArray(data)) {
      // Manually add price suggestion if searchValue is only an integer
      if (value.match(/^\d+$/)) {
        data.push({ match: value, type: 'Price' });
      }
      // if there no keyword item on suggestions, add one
      if (!data.some((d) => d.type === 'Keyword')) {
        data.push({ match: value, type: 'Keyword' as const });
      }
      setSuggestions(data.map((d) => ({ result: d, searchValue: value })));
    }
  }, 600);

  const handleKeywordSearch = () => {
    // Cancel debounce on search
    getSuggestions.cancel();

    if (query.result?.match.length >= 2) {
      // directoryStore.clearFilters();
      if (suggestions.length > 0) {
        suggestionSelected(suggestions[0]);
      } else {
        suggestionSelected(query);
      }
    } else if (searchValue.trim().length >= 2) {
      // directoryStore.addFilters([new Filter('query', searchValue.trim())]);
    }
  };

  const suggestionSelected = useCallback(
    (suggestion: Suggestion) => {
      setQuery(emptyQuerySuggestion);
      selectSuggestion(suggestion.result);
      setSearchValue('');
    },
    [selectSuggestion],
  );

  const suggestionsClearRequested = useCallback(() => {
    getSuggestions.cancel();
    setSuggestions([]);
  }, [getSuggestions]);

  return (
    <SearchBase<Suggestion>
      suggestions={suggestions}
      onSuggestionSelected={(_, { suggestion }) => suggestionSelected(suggestion)}
      onSuggestionsClearRequested={suggestionsClearRequested}
      onSuggestionsFetchRequested={({ value }) => getSuggestions(value)}
      getSuggestionValue={(suggestion: Suggestion) => capitalize(suggestion.result.match)}
      onSuggestionHighlighted={({ suggestion }) => {
        if (suggestion) setQuery(suggestion);
      }}
      highlightFirstSuggestion
      renderSuggestion={RenderSuggestion}
      shouldRenderSuggestions={(value) => allowSearch(value)}
      inputValue={searchValue}
      placeholder={HeroSearch.placeholder}
      suggestionResultsHeading={HeroSearch.resultsHeading}
      onInputChange={(_e, params) => setSearchValue(params.newValue)}
      onInputKeyPress={(e) => e.key === 'Enter' && handleKeywordSearch()}
      onClearButtonClick={() => {
        setQuery(emptyQuerySuggestion);
        setSearchValue('');
        setSuggestions([]);
      }}
      onSearchButtonClick={() => handleKeywordSearch()}
      styleSearchOverride={styles.search}
      styleInputOverride={styles.inputWrapper}
    />
  );
};

HeroSearch.placeholder = 'Search makes, models ...';
HeroSearch.resultsHeading = 'Search Suggestions';

export default observer(HeroSearch);
