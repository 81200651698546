import React, { useMemo } from 'react';

import { SlotButtonsProps } from './slots/Buttons';
import { SlotChecklistProps } from './slots/Checklist';
import { SlotDynamicButtonProps } from './slots/DynamicButton';
import { SlotDynamicFormProps } from './slots/DynamicForm';
import { SlotHeadingProps } from './slots/Heading';
import { SlotHeadingWithIconProps } from './slots/HeadingWithIcon';
import { SlotImageProps } from './slots/Image';
import { SlotListProps } from './slots/List';
import { SlotParagraphProps } from './slots/Paragraph';
import { SlotStaticButtonProps } from './slots/StaticButton';
import { VariantProps } from './variants';
import Hero from './variants/Hero';
import HeroLanding from './variants/HeroLanding';
import HeroWithForm from './variants/HeroWithForm';
import HeroWithSearch from './variants/HeroWithSearch';
import HeroWithVideo from './variants/HeroWithVideo';
import HomepageHero from './variants/HomepageHero';
import HomepageHeroWithCarousel from './variants/HomepageHeroWithCarousel';
import HomepageHeroWithForm from './variants/HomepageHeroWithForm';
import HomepageHeroWithImage from './variants/HomepageHeroWithImage';
import HomepageHeroWithVideo from './variants/HomepageHeroWithVideo';

export default function HeroModule(props: VariantProps) {
  const variant = useMemo(() => {
    switch (props.variant) {
      case 'default':
        return <Hero {...props} />;
      case 'withForm':
        return <HeroWithForm {...props} />;
      case 'withVideo':
        return <HeroWithVideo {...props} />;
      case 'homepageHero':
        return <HomepageHero {...props} />;
      case 'homepageHeroWithCarousel':
        return <HomepageHeroWithCarousel {...props} />;
      case 'homepageHeroWithForm':
        return <HomepageHeroWithForm {...props} />;
      case 'homepageHeroWithImage':
        return <HomepageHeroWithImage {...props} />;
      case 'homepageHeroWithVideo':
        return <HomepageHeroWithVideo {...props} />;
      case 'heroLanding':
        return <HeroLanding {...props} />;
      case 'withSearch':
        return <HeroWithSearch {...props} />;
      /* istanbul ignore next */
      default:
        return null;
    }
  }, [props]);

  return variant;
}

export class HeroSlots {
  public static Heading = (heading: string): SlotHeadingProps => ({ heading, type: 'slotHeading' });
  public static HeadingWithIcon = (heading: string, icon?: Maybe<IconFragment>): SlotHeadingWithIconProps => ({
    heading,
    icon,
    type: 'slotHeadingWithIcon',
  });
  public static Paragraph = (paragraph: string): SlotParagraphProps => ({ paragraph, type: 'slotParagraph' });
  public static Buttons = (
    button1: CustomLinkFieldFragment,
    button2?: CustomLinkFieldFragment,
    buttonType?: string,
  ): SlotButtonsProps => ({
    button1,
    button2,
    buttonType,
    type: 'slotButtons',
  });
  public static List = (list: string): SlotListProps => ({ list, type: 'slotList' });
  public static Checklist = (checklist: Maybe<ChecklistFragment>[]): SlotChecklistProps => ({
    checklist,
    type: 'slotChecklist',
  });
  public static Image = (label: string, images: Maybe<AssetFragment>[]): SlotImageProps => ({
    label,
    images,
    type: 'slotImage',
  });
  public static DynamicButton = (button: SubHeroHomepageCtaDynamicButtonFragment): SlotDynamicButtonProps => ({
    button,
    type: 'slotDynamicButton',
  });
  public static DynamicForm = (form: SubHeroHomepageCtaDynamicFormFragment): SlotDynamicFormProps => ({
    form,
    type: 'slotDynamicForm',
  });
  public static StaticButton = (
    button: SubHeroHomepageStaticButtonFragment | SubHeroLandingContentStaticButtonFragment,
  ): SlotStaticButtonProps => ({
    button,
    type: 'slotStaticButton',
  });
}
