import { HeroSlots } from '@/components/HeroModule';
import { HeroSlotType } from '@/components/HeroModule/slots';
import { SlotImageProps } from '@/components/HeroModule/slots/Image';
import { extractItems } from '@/lib/GraphQLHelper';

type ChildNode = SubHeroImageFragment;

export function convertToSlot(node: ChildNode): Nullable<HeroSlotType> {
  switch (node.__typename) {
    case 'subHeroImages_image_BlockType':
      if (!node.image) return null;
      return HeroSlots.Image(node.plainText!, node.image);
    default:
      return null;
  }
}

export default function convertToImageSlots(node?: Nullable<SubHeroImagesFragment>) {
  const nodes =
    extractItems<ChildNode>(node?.nodes)
      ?.map(convertToSlot)
      .filter((s): s is SlotImageProps => !!s) || [];

  return nodes;
}
