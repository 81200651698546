import React from 'react';

import HeadlinesWithBorder from '../../common/HeadlinesWithBorder';
import HeroVideoBackground from '../../common/HeroVideoBackground';
import Pills from '../../common/Pills';
import ScrollMoreText from '../../common/ScrollMoreText';
import Slots, { HeroSlotType } from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface HomepageHeroWithVideoProps extends BaseVariantProps {
  headlines: string[];
  videos: VideoAssetFragment[];
  image?: Nullable<AssetFragment>;
  pills: Nullable<HomepageHeroCtaPillsFragment[]>;
  variant: 'homepageHeroWithVideo';
}

const HomepageHeroWithVideo = ({ slots, headlines, videos, image, pills }: HomepageHeroWithVideoProps) => {
  const includeCarButtonOrForm = slots.some((slot: HeroSlotType) =>
    ['slotDynamicButton', 'slotDynamicForm'].includes(slot.type),
  );
  return (
    <div className={styles.homepageHeroWithVideo}>
      <HeroVideoBackground videos={videos} poster={image!} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.slotsWrapper}>
            {headlines.length >= 1 && <HeadlinesWithBorder headlines={headlines} />}
          </div>
          <div className={styles.side}>
            {includeCarButtonOrForm && <h2>Find your next car</h2>}
            <div className={styles.formContainer}>
              {slots.map((slot, index) => (
                <React.Fragment key={`${slot.type}-${index}`}>
                  {index === slots.length - 1 && index !== 0 && <div className={styles.or}>or</div>}
                  <Slots slots={[slot]} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      {!!pills && pills.length > 0 && <Pills items={pills} />}
      <ScrollMoreText />
    </div>
  );
};

export default HomepageHeroWithVideo;
