import 'keen-slider/keen-slider.min.css';

import React, { useCallback, useEffect, useState } from 'react';
import cc from 'classcat';
import { useKeenSlider } from 'keen-slider/react';

import Image from '@/components/Image';
import { IconArrowLeft, IconArrowRight, IconPlayerPause, IconPlayerPlay } from '@/images/icons/tabler-icons';

import { SlotImageProps } from '../../slots/Image';

import styles from './styles.module.scss';

interface Props {
  images: SlotImageProps[];
}

const HeroCarousel = ({ images }: Props) => {
  const [played, setPlayed] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [opacities, setOpacities] = React.useState<number[]>([]);

  const [counter, setCounter] = useState(0);
  const [slideChangeStart, setSlideChangeStart] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    animationStarted() {
      setSlideChangeStart(true);
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel);
      setSlideChangeStart(false);
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion);
      setOpacities(newOpacities);
    },
    slides: images.length,
    loop: true,
    initial: 0,
    drag: false,
    defaultAnimation: {
      duration: 300,
      easing: (t) => t,
    },
  });

  useEffect(() => {
    const timerId = setInterval(() => {
      if (played) {
        setCounter((c) => (c + 1) % (HeroCarousel.timeChange + 1));
        if (counter === HeroCarousel.timeChange) {
          instanceRef.current?.next();
        }
      }
    }, 500);

    return () => {
      clearInterval(timerId);
    };
  }, [counter, played, instanceRef]);

  const handlePlayClick = useCallback(() => {
    setPlayed(!played);
  }, [played]);

  const changeSlide = useCallback(
    (index: number) => {
      if (currentSlide !== index) {
        instanceRef.current?.moveToIdx(index);
        setCounter(0);
      }
    },
    [instanceRef, currentSlide],
  );

  const nextSlide = useCallback(() => {
    instanceRef.current?.next();
    setCounter(0);
  }, [instanceRef]);

  const prevSlide = useCallback(() => {
    instanceRef.current?.prev();
    setCounter(0);
  }, [instanceRef]);

  return (
    <div className={styles.carouselContainer}>
      <div ref={sliderRef} className="keen-slider keen-slider-carousel fader">
        {images.map(
          (image, idx) =>
            image.images[0]?.jpg && (
              <div key={idx} className="fader__slide" style={{ opacity: opacities[idx] }}>
                <Image
                  src={image.images[0]?.jpg}
                  alt={image.label}
                  width={1200}
                  height={790}
                  className={styles.image}
                />
              </div>
            ),
        )}
      </div>

      <div className={styles.buttons}>
        <div className={styles.buttonsLeft}>
          <div className={styles.buttonsContainer}>
            <button onClick={handlePlayClick} className={styles.playButton}>
              {played ? <IconPlayerPause /> : <IconPlayerPlay />}
            </button>
            {images.map(
              (image, idx) =>
                image.images[0]?.jpg && (
                  <button
                    className={cc({ [styles.slideButton]: true, [styles.slideButtonActive]: currentSlide === idx })}
                    key={idx}
                    onClick={() => changeSlide(idx)}
                  >
                    {image.label}
                    <div
                      className={styles.slideProgress}
                      style={{ width: `${slideChangeStart ? 100 : (100 * counter) / HeroCarousel.timeChange}%` }}
                    />
                  </button>
                ),
            )}
          </div>
        </div>
        <div>
          <div className={styles.buttonsContainer}>
            <button onClick={prevSlide} className={styles.playButton}>
              <IconArrowLeft />
            </button>
            <button onClick={nextSlide} className={styles.playButton}>
              <IconArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroCarousel.timeChange = 10;

export default HeroCarousel;
