import 'keen-slider/keen-slider.min.css';

import React, { useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';

import { getIcon } from '@/lib/IconHelper';

interface Props {
  items: HomepageHeroCtaPillsFragment[];
}

const PillsCarousel = ({ items }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel);
    },
    loop: true,
    initial: 0,
    vertical: true,
    drag: false,
    defaultAnimation: {
      duration: 300,
      easing: (t) => t,
    },
  });

  useEffect(() => {
    const slideInterval = setInterval(() => {
      instanceRef.current?.next();
    }, 5000);

    return () => {
      clearInterval(slideInterval);
    };
  }, [currentSlide, instanceRef]);

  return (
    <div ref={sliderRef} className="keen-slider keen-slider-pills">
      {items.map((p, i) => (
        <div key={i} className="keen-slider__slide keen-slider-pill__slide">
          {getIcon(p.icon)} {p.text}
        </div>
      ))}
    </div>
  );
};

export default PillsCarousel;
