import React from 'react';

import Tick from '@/images/icons/tabler-checkbox.svg';

import styles from './styles.module.scss';

export interface SlotChecklistProps {
  checklist: Maybe<ChecklistFragment>[];
  type: 'slotChecklist';
}

export default function SlotChecklist({ checklist }: SlotChecklistProps) {
  return (
    <ul className={styles.checklist}>
      {checklist.map(
        (c, i) =>
          c &&
          c.content && (
            <li key={`${i}-outer-${c.content}`} className={styles.item}>
              <div className={styles.check}>
                <Tick />
              </div>
              <div dangerouslySetInnerHTML={{ __html: c.content }} key={`inner-${c.content}`} />
            </li>
          ),
      )}
    </ul>
  );
}
