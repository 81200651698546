import React, { useCallback, useMemo, useRef, useState } from 'react';

import { IconPlayerPause, IconPlayerPlay } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

interface Props {
  videos: VideoAssetFragment[];
  poster: Nullable<AssetFragment>;
}

export default function HeroVideoBackground({ videos, poster }: Props) {
  const [played, setPlayed] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const videoProps = poster?.jpg ? { poster: poster.jpg } : {};
  const urls = useMemo(() => {
    return videos.filter((video) => {
      const extension = video.url?.split('.').pop();
      return extension && ['webm', 'mp4', 'ogg'].includes(extension.toLowerCase());
    });
  }, [videos]);

  const handlePlayClick = useCallback(() => {
    if (videoRef.current) {
      if (!played) {
        videoRef.current.play();
        setPlayed(true);
      } else {
        videoRef.current.pause();
        setPlayed(false);
      }
    }
  }, [played]);

  if (urls.length > 0) {
    return (
      <div className={styles.videoContainer}>
        <video ref={videoRef} {...videoProps} loop autoPlay muted playsInline>
          {videos.map(
            (video) =>
              video.url && (
                <source key={video.url} src={video.url} type={`video/${video.url.split('.').pop()?.toLowerCase()}`} />
              ),
          )}
          <track kind="captions" />
        </video>
        <div className={styles.videoOverlay} />
        <button
          className={styles.playButton}
          onClick={handlePlayClick}
          aria-label={played ? 'Pause video' : 'Play video'}
        >
          {played ? <IconPlayerPause /> : <IconPlayerPlay />}
        </button>
      </div>
    );
  }

  return null;
}
