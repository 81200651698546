import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';

import convertToSlots from '../submodules/SubHeroContent';
import convertToImageSlots from '../submodules/SubHeroImages';

interface Props {
  node: HeroContentFragment;
  children?: React.ReactNode;
}

export default function ModuleGenericWithForm({ node, children }: Props) {
  const slots = useMemo(() => convertToSlots(node.subHeroContent), [node.subHeroContent]);
  const imageSlots = useMemo(() => convertToImageSlots(node.subHeroImages), [node.subHeroImages]);
  const fullImageStyle = !!node.toggle;

  return (
    <HeroModule
      variant="default"
      slots={slots}
      image={node.image?.[0]}
      fullImageStyle={fullImageStyle}
      imageSlots={imageSlots}
    >
      {children}
    </HeroModule>
  );
}
