import React from 'react';
import cc from 'classcat';

import ImageOrPlaceholder from '@/components/ContentModule/common/ImageOrPlaceholder';

import FeaturedImages from '../../common/FeaturedImages';
import Slots from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface DefaultProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  fullImageStyle?: boolean;
  variant: 'default';
}

export default function Hero({ slots, image, fullImageStyle, imageSlots, children }: DefaultProps) {
  return (
    <React.Fragment>
      <div
        className={cc({
          [styles.hero]: true,
          [styles.fullwidth]: !image,
          [styles.fullImageStyle]: image && fullImageStyle,
        })}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.slotsWrapper}>
              <Slots slots={slots} alignment={image ? 'left' : 'center'} />
              {children}
            </div>
            {!fullImageStyle && image && <ImageOrPlaceholder image={image} className={styles.image} />}
          </div>
          {fullImageStyle && image && (
            <div className={styles.fullImage}>
              <ImageOrPlaceholder image={image} className={styles.image} />
            </div>
          )}
        </div>
      </div>
      {imageSlots.length > 0 && (
        <FeaturedImages>
          <Slots slots={imageSlots} />
        </FeaturedImages>
      )}
    </React.Fragment>
  );
}
