import React from 'react';
import cc from 'classcat';

import ImageOrPlaceholder from '@/components/ContentModule/common/ImageOrPlaceholder';
import Search from '@/components/HeroModule/common/Search';

import Slots from '../../slots';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface WithSearchProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  variant: 'withSearch';
}

export default function HeroWithSearch({ slots, image, children }: WithSearchProps) {
  return (
    <React.Fragment>
      <div
        className={cc({
          [styles.hero]: true,
          [styles.fullwidth]: !image,
        })}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.slotsWrapper}>
              <Slots slots={slots} alignment="center" />
              {children}
              <div className={styles.search}>
                <Search />
              </div>
            </div>
            {image && <ImageOrPlaceholder image={image} className={styles.image} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
