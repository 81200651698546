import React from 'react';
import cc from 'classcat';
import { DeepMap, FieldError } from 'react-hook-form';
import { Field } from 'formik';

import Chevron from '@/images/icons/chevron--down.svg';

import styles from './styles.module.scss';

export interface Option {
  title: string;
  value: string | number;
  disabled?: boolean;
}

type Props = {
  name: string;
  inputRef?: ((instance: HTMLSelectElement | null) => void) | React.RefObject<HTMLSelectElement>;
  label?: string;
  errors?: DeepMap<Record<string, any>, FieldError>;
  error?: boolean;
  options: Option[];
  withoutBorder?: boolean;
  formik?: boolean;
  placeholder?: string;
  errorText?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
} & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

const DealershipBarSelect = ({
  label,
  errors,
  inputRef,
  options,
  withoutBorder,
  style,
  formik,
  placeholder,
  error,
  errorText,
  ...props
}: Props) => (
  <div
    className={cc({
      [styles.select]: true,
      [styles.disabled]: props.disabled,
      [styles.withoutBorder]: withoutBorder,
      [styles.error]: error || errors?.[props.name],
    })}
    style={style}
  >
    {label && (
      <label htmlFor={props.name} className={cc({ [styles.disabled]: props?.disabled })} data-testid="label">
        {label}
      </label>
    )}
    <div className={cc({ [styles.icon]: true, [styles.hasLabel]: label })}>
      <Chevron />
    </div>
    {formik ? (
      <Field
        as="select"
        {...props}
        className={cc({ [styles.field]: true, [styles.hasLabel]: label, [styles.withoutBorder]: withoutBorder })}
      >
        {placeholder && <option defaultValue="">{placeholder}</option>}
        {options?.map((o: Option) => (
          <option value={o.value} key={o.value} disabled={o?.disabled}>
            {o.title}
          </option>
        ))}
      </Field>
    ) : (
      <select
        ref={inputRef}
        {...props}
        className={cc({ [styles.field]: true, [styles.hasLabel]: label, [styles.withoutBorder]: withoutBorder })}
      >
        {placeholder && <option defaultValue="">{placeholder}</option>}
        {options?.map((o: Option) => (
          <option value={o.value} key={o.value} disabled={o?.disabled}>
            {o.title}
          </option>
        ))}
      </select>
    )}
    {errorText && <p data-testid="error">{errorText}</p>}
    {errors?.[props.name] && <p data-testid="error">{errors[props.name]?.message}</p>}
  </div>
);

export default DealershipBarSelect;
