import React from 'react';

import useIsMobile from '@/hooks/useIsMobile';
import { getIcon } from '@/lib/IconHelper';

import PillsCarousel from './carousel';

import styles from './styles.module.scss';

interface Props {
  items: HomepageHeroCtaPillsFragment[];
}

const Pills = ({ items }: Props) => {
  const [isTablet] = useIsMobile(1024);

  return (
    <div className={styles.pills}>
      <div className={styles.pillsContainer}>
        {isTablet ? (
          <PillsCarousel items={items} />
        ) : (
          <div className={styles.pillsDesktop}>
            {items.map((p, i) => (
              <span key={i}>
                {getIcon(p.icon)} {p.text}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pills;
