import React from 'react';

import styles from './styles.module.scss';

export interface FormBlockProps {
  children: React.ReactNode;
}

export default function FeaturedImages({ children }: FormBlockProps) {
  return (
    <div className={styles.featured}>
      <div className={styles.featuredContainer}>{children}</div>
    </div>
  );
}
