import React from 'react';
import cc from 'classcat';

import Image from '@/components/Image';

import PlaceholderIcon from '@/images/icons/placeholder.svg';

import styles from './styles.module.scss';

interface SlotImageProps {
  label: string;
  images: Maybe<AssetFragment>[];
  type: 'slotImage';
}

export default function SlotImage({ label, images }: SlotImageProps) {
  const image = images?.[0];
  return (
    <div className={styles.slotImage}>
      {image ? (
        <Image data-testid="slotImage" {...image} />
      ) : (
        <div data-testid="placeholder" className={cc([styles.placeholder])}>
          <PlaceholderIcon />
        </div>
      )}
      {!!label && <label className={styles.label}>{label}</label>}
    </div>
  );
}

export type { SlotImageProps };
