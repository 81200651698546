import React from 'react';

import HeroSearch from '../../common/HeroSearch';

export interface SlotDynamicFormProps {
  form: SubHeroHomepageCtaDynamicFormFragment;
  type: 'slotDynamicForm';
}

export type slotDynamicFormVariants = 'carSearchForm';

export default function SlotDynamicForm({ form }: SlotDynamicFormProps) {
  // Switch case render variants
  switch (form.heroModuleDynamicFormVariants as slotDynamicFormVariants) {
    case 'carSearchForm':
    default:
      return <HeroSearch />;
  }
}
