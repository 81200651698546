import React from 'react';

import HeroFormButton from './HeroFormButton';
import RegoLookupBlock from './RegoLookupBlock';

import styles from './styles.module.scss';

export interface HeroFormBlockProps {
  heading: string;
  paragraph?: string;
  buttons?: { link: CustomLinkFieldFragment; icon: Maybe<IconFragment>; buttonStyle: Maybe<string> }[];
  regoLookupForm?: SubHeroFormRegoLookupFragment;
}

export default function HeroFormBlock({ heading, paragraph, buttons, regoLookupForm }: HeroFormBlockProps) {
  return (
    <div className={styles.formBlock}>
      <div className={styles.heading} dangerouslySetInnerHTML={{ __html: heading }} />
      {paragraph && <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />}
      {buttons?.map((b, idx) => (
        <HeroFormButton key={b.link.text} button={b} transparent={!(buttons.length === 1 || idx % 2)} />
      ))}
      {regoLookupForm?.regoLookupFormType && (
        <RegoLookupBlock
          formType={regoLookupForm.regoLookupFormType}
          buttonStyle={regoLookupForm.regoLookupButtonStyle || 'action'}
          mainDealershipSlug={regoLookupForm.mainDealershipSlug!}
        />
      )}
    </div>
  );
}
