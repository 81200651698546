import React, { useState } from 'react';
import { useRouter } from 'next/router';

import Button from '@/components/Button';
import { IconSearch, IconX } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

type Props = {
  placeholder?: string;
};

const Search = ({ placeholder = 'Search here' }: Props) => {
  const router = useRouter();
  const [value, setValue] = useState('');
  const [searchActive, setSearchActive] = useState(false);

  const updateURL = (value: string) => {
    const url = new URL(router.asPath.split('?')[0], window.location.origin);
    if (value) {
      url.searchParams.set('search', value);
    } else {
      url.searchParams.delete('search');
    }
    window.history.pushState('', '', url);

    // Force router object search param to shallow update router-based dependencies
    if (router.isReady) {
      router.query.search = value;
    }
  };

  const onSearch = () => {
    if (value.length < 3) return;
    setSearchActive(true);
    updateURL(value);
  };

  const onClearButtonClick = () => {
    if (searchActive) {
      setSearchActive(false);
      updateURL('');
    }
    setValue('');
  };

  return (
    <div className={styles.search}>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          maxLength={64}
          onChange={(e) => setValue(e.currentTarget.value)}
          onKeyDown={(e) => e.key === 'Enter' && onSearch()}
        />
        {(value.length > 0 || searchActive) && (
          <button onClick={onClearButtonClick} className={styles.clearButton}>
            <IconX height={24} width={24} />
          </button>
        )}
      </div>
      <Button styleOverride={styles.searchButton} disabled={value.length < 3} onClick={onSearch} aria-label="Search">
        <IconSearch width={24} height={24} />
      </Button>
    </div>
  );
};

export default Search;
