import 'keen-slider/keen-slider.min.css';

import React, { useEffect, useMemo, useState } from 'react';
import cc from 'classcat';
import { useKeenSlider } from 'keen-slider/react';

import styles from './styles.module.scss';

interface Props {
  headlines: string[];
}

const HeadlinesWithBorder = ({ headlines }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [borderStart, setBorderStart] = useState(false);
  const [borderEnd, setBorderEnd] = useState(false);

  const slides = useMemo(() => {
    const arr: string[] = [];
    for (let i = 0; i < headlines.length; i++) {
      arr.push('');
      arr.push(headlines[i]);
    }
    return arr;
  }, [headlines]);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel);
    },
    loop: true,
    initial: 0,
    vertical: true,
    drag: false,
    defaultAnimation: {
      duration: 300,
      easing: (t) => t,
    },
  });

  useEffect(() => {
    let slideInterval: NodeJS.Timeout;
    let borderInterval: NodeJS.Timeout;

    if (currentSlide % 2 === 0) {
      setBorderStart(false);
      setBorderEnd(false);

      borderInterval = setInterval(() => {
        setBorderStart(true);
      }, 200);

      slideInterval = setInterval(() => {
        instanceRef.current?.next();
      }, 500);
    } else {
      borderInterval = setInterval(() => {
        setBorderEnd(true);
      }, 4700);

      slideInterval = setInterval(() => {
        instanceRef.current?.next();
      }, 5000);
    }

    return () => {
      clearInterval(borderInterval);
      clearInterval(slideInterval);
    };
  }, [currentSlide, instanceRef, headlines]);

  return (
    <div ref={sliderRef} className="keen-slider keen-slider-headline-small keen-slider-headline-with-border">
      {slides.map((headline, idx) => (
        <div key={idx} className="keen-slider__slide">
          {headline}
        </div>
      ))}
      <div className={cc({ [styles.headlineBorder]: true, [styles.start]: borderStart, [styles.end]: borderEnd })} />
    </div>
  );
};

export default HeadlinesWithBorder;
